import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  activeGroup: {
    name: null,
    id: null,
  },
  allGroups: [],
}

export const groupsSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    setActiveGroup: (state, action) => {
      state.activeGroup = { ...state.activeGroup, ...action.payload}
    },
    setAllGroups: (state, action) => {
      state.allGroups = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setActiveGroup, setAllGroups } = groupsSlice.actions

export default groupsSlice.reducer