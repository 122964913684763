import * as React from 'react';

import LogInButton from "../../reusableComponents/buttons/logInButton";
import SignUpButton from "../../reusableComponents/buttons/signUpButton";

import SubTitle from "../../reusableComponents/subtitle";
import Title from "../../reusableComponents/title";
import "./index.css";

import {db, auth} from '../../firebase';
import Footer from "../../reusableComponents/footer";


export default function LandingPage() {


    // const [isUserSignedIn, setUserIsSignedIn] = React.useState(false);

    React.useEffect(() => {
        auth.onAuthStateChanged((user) => {
            if (user) {
                // setUserIsSignedIn(true);
                window.location.pathname = '/groups'
                
            } else {
                // setUserIsSignedIn(false);
            }
        });
    }, [])

    db.ref('poopPath/').set({
        poopAmount: '10kgs'
    })

    return(
        <div className="notsignedinhomepage-root">
            <div className="notsignedinhomepage-header-holder title-holder-lg">
                <Title />
                <SubTitle />
                <div className="notsignedinhomepage-authentication-buttons-holder">
                    <SignUpButton />
                    <LogInButton />
                </div>
            </div>
            <Footer />
        </div>
    )
}