import * as React from 'react';

// import QuwLogo from '../../resources/images/quw1.jpg';

import './index.css';

import Title from '../../reusableComponents/title/index';

import TemplateButton from '../../reusableComponents/buttons/templateButton';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Grid from '@mui/material/Grid';
import { TextField } from '@mui/material';

import { auth, db } from '../../firebase';

import { useSelector, useDispatch } from 'react-redux'

import { getAndSetGroups, setActiveGroupInDB } from '../../utility/groupFunctions';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

let groupName = ''
const groupNameChange = (e) => {
    groupName = e.target.value;
}

export default function GroupsPage() {
    const dispatch = useDispatch()
    const activeGroup = useSelector((state) => state.groups.activeGroup);
    const allGroups = useSelector((state) => state.groups.allGroups);

    React.useEffect(() => {
        auth.onAuthStateChanged((user) => {
            if (user) {
                getAndSetGroups(dispatch, user);
            } else {
                window.location.path = '/'
            }
        });
    }, [dispatch])

    const [expandCreate, setExpandCreate] = React.useState(false);


    const validateGroup = () => {
        if (groupName.length < 3) {
            alert('Group name has to be at least 3 characters long.');
            return false;
        } else if (groupName.length > 19) {
            alert('Group name cannot be greater than 18 characters.');
            return false;
        }
        return true;
    }

    const createGroup = () => {
        if(validateGroup === false) {
            return;
        }

        // Create new group unique ID, and create 
        // it's first member as this signed in user.
        let uid = auth.currentUser.uid;
        let newGroupPath = db.ref().child('groups/').push();
        newGroupPath.set({
            groupName: groupName,
        }).then(() => {
            newGroupPath.child("members").set({
                [uid]: true,
            }).then(() => {
                // Then update the user's own member list and
                // previously active group
                let userPath = db.ref().child('users/').child(uid);
                userPath.child('/memberships/').update({
                    [newGroupPath.key]: groupName,
                }).then(() => {
                    userPath.child('/lastActive/').set({
                        groupId: newGroupPath.key,
                        groupName: groupName
                    }).then(() => {
                        window.location.pathname = '/calendar';
                    })
                });
            });
        });
    }

    const openActiveGroup = (groupId, groupName) => {
        setActiveGroupInDB(dispatch, auth.currentUser.uid, groupId, groupName)
        window.location.pathname = '/calendar';
    }
    
    return(
        <div className="dashboard-root">
            <div className='dashboard-title no-select'>
                <Title />
            </div>
            <div className='dashboard-groups-holder'> 
                    <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <div className='dashboard-active-group-holder'>
                                    <div id='title'>Active Group:</div>
                                    {console.info(activeGroup)}
                                    {
                                        activeGroup.name === null && activeGroup.id === null
                                        ?
                                        ''
                                        :
                                        <TemplateButton
                                            color='primary'
                                            text={ activeGroup.name }
                                            onClickFunction={() => openActiveGroup(activeGroup.id, activeGroup.name)}
                                            
                                        />
                                    }
                                    
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div className='dashboard-all-groups-holder'>
                                    <div id='title'>All Groups:</div>
                                    <div className='dashboard-all-groups-button-holder'>
                                    {console.info(allGroups)}
                                    {
                                        allGroups.map((group) => {
                                            return (
                                                <TemplateButton
                                                    color='secondary'
                                                    text={ group.name }
                                                    size='small'
                                                    onClickFunction={() => openActiveGroup(group.id, group.name)}
                                                    
                                                />
                                            )
                                        })
                                    }
                                    </div>
                                    
                                </div>
                            
                            </Grid>
                    </Grid>
                </div>
            <div className='dashboard-group-holder'>
                <TemplateButton 
                    color='primary'
                    text={'CREATE A NEW GROUP'}
                    onClickFunction={() => setExpandCreate(true)}
                    type='groups-page-button'
                />
                <TemplateButton 
                    color='secondary' 
                    text={'JOIN A NEW GROUP'} 
                    type='groups-page-button'
                />
                <Dialog
                    maxWidth={"xl"}
                    open={expandCreate}
                    onClose={() => setExpandCreate(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    TransitionComponent={Transition}
                >
                    <DialogTitle id="alert-dialog-title">
                        {/* <img src={QuwLogo} /> */}
                        Create a Group!
                        <div id='alert-dialog-subtitle'>
                            Group names must be at least 3 characters long.
                        </div>
                    </DialogTitle>
                    <DialogContent>
                        <div className='group-create-dialog dialog-form-holder'>
                            <div className='form-gap'/>
                            <div className='form-row'>
                                <div className='form-prompt'>Group Name: </div>
                                <div className='form-field'>
                                    <DialogContentText id="alert-dialog-description">
                                        <TextField 
                                            required={true}
                                            placeholder="Group Name"
                                            fullWidth={true}
                                            // error={true}
                                            inputProps={{style: {fontSize: '1vw', position: 'relative', bottom: 5}}}
                                            variant='standard'
                                            onChange={groupNameChange}
                                            onKeyPress={(event) => {
                                                if (event.key === 'Enter')
                                                    createGroup();
                                            }}
                                        />
                                    </DialogContentText>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <div className='form-submit-buttons-holder'>
                            <div className='bigbutton-outer bigbutton-outer-secondary bigbutton-template bump-right no-select' onClick={() => setExpandCreate(false)}>Cancel</div>
                            <div className='bigbutton-outer bigbutton-outer-primary bigbutton-template no-select' onClick={createGroup} >
                                Create Group
                            </div>
                        </div>
                    </DialogActions>
                </Dialog>
            </div>
        </div>
    )
}