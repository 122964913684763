import * as React from 'react';

import './App.css';
import LandingPage from './pageRoots/landingPage';
import Calendar from './pageRoots/calendar';
import Members from './pageRoots/members';
import Profile from './pageRoots/profile';
import GroupsPage from './pageRoots/groupsPage';
import Chat from './pageRoots/chat';
import Budget from './pageRoots/budget';

import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';

function App() {

  return (
    <div className="app-root">
      <Router>
        <Routes>
          <Route exact path='/'             element={<   LandingPage  />}></Route>
          <Route exact path='/groups'       element={<   GroupsPage   />}></Route>
          <Route exact path='/calendar'     element={<   Calendar     />}></Route>
          <Route exact path='/members'      element={<   Members      />}></Route>
          <Route exact path='/chat'         element={<   Chat         />}></Route>
          <Route exact path='/budget'       element={<   Budget       />}></Route>
          <Route exact path='/profile'      element={<   Profile      />}></Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
