import Event from '../event';
import './index.css';


export default function Day({
    color,
    dayNum
}) {

    let author = ''
    if(Math.floor(Math.random() * 10) % 2 === 0) {
        author = 'Hamza'
    } else {
        author = 'Naadia'
    }

    let author2 = ''
    if(Math.floor(Math.random() * 10) % 2 === 0) {
        author2 = 'Hamza'
    } else {
        author2 = 'Naadia'
    }



    return(
        <div className={"day-root day-root-"+color} >
            <div className='day-num no-select'>
                {dayNum}
            </div>
            <div className='day-content'>
                {
                    color === 'red'
                    ?
                        <Event color="red" name="Anniversary" author="N&H" />
                    :
                        <span></span>
                }
                {
                    Math.floor(Math.random() * 10) % 7 === 0
                    ?
                        <Event color="pink" name="Errand" author={author} />
                    :
                        <span></span>
                }
                {
                    Math.floor(Math.random() * 10) % 8 === 0
                    ?
                        <Event color="yellow" name="Important" author={author2} />
                    :
                        <span></span>
                }
            </div>
        </div>
    )
}