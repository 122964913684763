import './../index.css';

import * as React from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TextField } from '@mui/material';

import { auth } from '../../../firebase';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

let emailText = ''
let emailConfirmText = ''
let passwordText = ''
let passwordConfirmText = ''

export default function SignUpButton({text, color, size}) {
    
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    // TODO: Disable create account button if fields are not filled or incorrect
    // TODO: Put error state on email fields if not following regX pattern
    // TODO: error state on password if not matching

    const validateEmails = () => {
        if (emailText.length === 0) {
            alert("Email cannot be empty!")
            return false;
        }
        else if (emailText !== emailConfirmText) {
            alert("Emails don't match!")
            return false;
        }
        return true;
    }

    const validatePasswords = () => {
        if (passwordText.length === 0) {
            alert("Password cannot be empty!")
            return false;
        }
        else if (passwordText !== passwordConfirmText) {
            alert("Passwords don't match!")
            return false;
        }
        return true;
    }

    const createAccount = () => {
        if(validateEmails() && validatePasswords()) {
            // Create account
            // window.location.pathname = '/calendar'

            auth.createUserWithEmailAndPassword(emailText, passwordText)
            .then((userCredential) => {
                // TODO: Setup user DB stuff
            })
            .catch((error) => {
                // var errorCode = error.code;
                var errorMessage = error.message;
                alert(errorMessage)
            });
        }
    }

    return (
        <div className='bigbutton-outer-holder'>
            <div 
                className={"bigbutton-outer bigbutton-outer-primary no-select"}
                onClick={handleClickOpen}
            >
                SIGN UP
            </div>
            <Dialog
                maxWidth={"xl"}
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                TransitionComponent={Transition}
            >
                <DialogTitle id="alert-dialog-title">
                    SIGN UP
                    <div id='alert-dialog-subtitle'>
                        Begin using Quwoperate by creating your new account below.
                    </div>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <div className='dialog-form-holder sign-up-form-holder auth-form'>
                            <div className='form-gap'/>
                            <div className='form-row'>
                                <div className='form-prompt'>
                                    Email Address
                                </div>
                                <div className='form-field'>
                                    <TextField 
                                        required={true}
                                        placeholder="example@abc.com"
                                        fullWidth={true}
                                        // error={true}
                                        inputProps={{style: {fontSize: '1vw', position: 'relative', bottom: 5}}}
                                        variant='standard'
                                        type='email'
                                        onChange={e => emailText = e.target.value}
                                    />
                                </div>
                            </div>
                            <div className='form-row'>
                                <div className='form-prompt'>
                                    Confirm Email
                                </div>
                                <div className='form-field'>
                                    <TextField 
                                        required={true}
                                        placeholder="Confirm your above email address"
                                        fullWidth={true}
                                        // error={true}
                                        inputProps={{style: {fontSize: '1vw', position: 'relative', bottom: 5}}}
                                        variant='standard'
                                        type='email'
                                        onChange={e => emailConfirmText = e.target.value}
                                    />
                                </div>
                            </div>
                            <div className='form-gap'/>
                            <div className='form-row'>
                                <div className='form-prompt'>
                                    Password
                                </div>
                                <div className='form-field'>
                                    <TextField 
                                        required={true}
                                        placeholder="Enter your password"
                                        fullWidth={true}
                                        // error={true}
                                        inputProps={{style: {fontSize: '1vw', position: 'relative', bottom: 5}}}
                                        variant="filled"
                                        type='password'
                                        onChange={e => passwordText = e.target.value}
                                    />
                                </div>
                            </div>
                            <div className='form-row'>
                                <div className='form-prompt'>
                                    Confirm Password
                                </div>
                                <div className='form-field'>
                                    <TextField 
                                        required={true}
                                        placeholder="Confirm your password"
                                        fullWidth={true}
                                        // error={true}
                                        inputProps={{style: {fontSize: '1vw', position: 'relative', bottom: 5}}}
                                        variant="filled"
                                        type='password'
                                        onChange={e => passwordConfirmText = e.target.value}
                                    />
                                </div>
                            </div>
                            <div className='form-gap'/>

                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <div className='form-submit-buttons-holder'>
                        <div className='bigbutton-outer bigbutton-outer-secondary bigbutton-template bump-right no-select' onClick={handleClose}>Cancel</div>
                        <div className='bigbutton-outer bigbutton-outer-primary bigbutton-template no-select' onClick={createAccount} >
                            Create Account
                        </div>
                    </div>
                </DialogActions>
            </Dialog>
            
        </div>
        
    )
}