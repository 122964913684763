import * as React from 'react';
import './index.css';

import { auth } from '../../firebase';

import Navbar from "../../reusableComponents/navbar";
import Week from './week/index.js';

import { getLastActiveGroup } from './../../utility/groupFunctions';

import { useSelector, useDispatch } from 'react-redux'

const moment = require('moment');

export default function Calendar() {
    const dispatch = useDispatch()
    const activeGroup = useSelector((state) => state.groups.activeGroup);

    React.useEffect(() => {
        auth.onAuthStateChanged((user) => {
            if (user) {
                getLastActiveGroup(dispatch, user.uid)
            } else {
                window.location.pathname = '/';
            }
        });
    }, [dispatch])

    var startOfWeek = moment().startOf('week').toDate();
    var endOfWeek   = moment().endOf('week').toDate();
    var lastDayInStartMonth = moment().daysInMonth();


    return (
        <div className='calendar-root'>
            <Navbar active='calendar' />
            {
                activeGroup !== null
                ?
                <div className='calendar-active-group-holder'>
                    <span id='title'>Group Name: </span><span id="info">{activeGroup.name}</span>
                    <br/>
                    <span id='subtitle'>Group ID: </span><span id="info">{activeGroup.id}</span>
                </div>
                :
                <div>
                    HELLO
                </div>
            }
            <div className='calendar-weeks-holder'>
                <Week 
                    startDay    ={startOfWeek.getDate()}
                    startMonth  ={startOfWeek.getMonth()}
                    startYear   ={startOfWeek.getFullYear()}

                    endDay      ={endOfWeek.getDate()}
                    endMonth    ={endOfWeek.getMonth()}
                    endYear     ={endOfWeek.getFullYear()}

                    startOfWeek ={startOfWeek}
                    endofWeek   ={endOfWeek}
                    lastDayInStartMonth={lastDayInStartMonth}
                />
                <Week 
                    startDay    ={startOfWeek.getDate()+7}
                    lastDayInStartMonth={lastDayInStartMonth}
                />
                <Week 
                    startDay    ={startOfWeek.getDate()+14}
                    lastDayInStartMonth={lastDayInStartMonth}
                />
                <Week 
                    startDay    ={startOfWeek.getDate()+21}
                    lastDayInStartMonth={lastDayInStartMonth}
                />
            </div>
        </div>
    )
}