import * as React from 'react';
import { auth } from '../../firebase';

import Navbar from "../../reusableComponents/navbar";

export default function Chat() {

    auth.onAuthStateChanged((user) => {
        if (user) {
        } else {
            window.location.pathname = '/';
        }
    });
    
    return (
        <div> 
            <Navbar active='chat' />
            CHAT???? AAAAAAAAAAAAAAAAAAAAAAAAAAAAH. SOMEBODY, PLEASE PLS MAN JSUT HELP ME ALREADY AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA. Ok, tnx. Buy!!!! :** 
        </div>
    )
}