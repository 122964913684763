import * as React from 'react';
import Day from '../day';

import './index.css';
var moment = require('moment');

export default function Week({
    startDay,
    startMonth,
    startYear,
    endDay,
    endMonth,
    endYear,
    startOfWeek,
    endofWeek,
    lastDayInStartMonth
}) {
    
    var days = []

    const getDays = () => {
        
        let daysCopy = days;
        for (let i = 0; i < 7; i++) {
            let dayNum = startDay + i;
            if (dayNum > lastDayInStartMonth) {
                dayNum %= lastDayInStartMonth;
            }
            
            if (dayNum === 14) {
                daysCopy[i] = <Day dayNum={dayNum} color='red'/>
            }
            else if (dayNum === moment().date()) {
                daysCopy[i] = <Day dayNum={dayNum} color='grey'/>
            } else {
                daysCopy[i] = <Day dayNum={dayNum} />
            }
        }

        days = daysCopy;
    }

    getDays();

    return(
        <div className="week-root">
            {days.map((day) => {
                return (
                    day
                )
            })}
        </div>
    )
}