import React from 'react'

import './index.css';

import TemplateButton from './../buttons/templateButton/index';
import LogoutIcon from '@mui/icons-material/Logout';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import GroupIcon from '@mui/icons-material/Group';
import ForumIcon from '@mui/icons-material/Forum';
import Grid from '@mui/material/Grid';

import {auth} from './../../firebase';

// import QuwLogo from '../../resources/images/quw1.jpg'

export default function Navbar({active}) {

    React.useEffect(() => {
        auth.onAuthStateChanged((user) => {
            if (user === null) {
                window.location.pathname = '/'
            }
        });
    }, [])

    const logOutUser = () => {
        auth.signOut().then(function() {
            console.log('User Logged Out!');
            window.location.pathname = '/'
        }).catch(function(error) {
            console.log(error);
        });
    }

    const changePathTo = (path) => {
        window.location.pathname = path;
    }

    return(

        <div className='navbar-root'>
            <div className='navbar-logo navbar-title no-select' onClick={() => changePathTo('/groups')}>
                {/* <img src={QuwLogo} />  */}
                Quwoperate
            </div>
            <div>
                <div className="navbar-item no-select">
                    <TemplateButton
                        icon={<CalendarMonthIcon style={{width: 25, height: 25}}/>}
                        text='Calendar'
                        color={active === 'calendar' ? 'primary' : 'secondary'}
                        onClickFunction={() => changePathTo('/calendar')}
                        type='navbar-button'
                    />
                </div>
                <div className="navbar-item no-select">
                    <TemplateButton
                        icon={<GroupIcon style={{width: 25, height: 25}}/>}
                        text='Members'
                        color={active === 'members' ? 'primary' : 'secondary'}
                        onClickFunction={() => changePathTo('/members')}
                        type='navbar-button'
                    />
                </div>
                <div className="navbar-item no-select">
                    <TemplateButton
                        icon={<InsertChartIcon style={{width: 25, height: 25}}/>}
                        text='Budget'
                        color={active === 'budget' ? 'primary' : 'secondary'}
                        onClickFunction={() => changePathTo('/budget')}
                        type='navbar-button'
                    />
                </div>
                <div className="navbar-item no-select">
                    <TemplateButton
                        icon={<ForumIcon style={{width: 25, height: 25}}/>}
                        text='Chat'
                        color={active === 'chat' ? 'primary' : 'secondary'}
                        onClickFunction={() => changePathTo('/chat')}
                        type='navbar-button'
                    />
                </div>
                <div className="navbar-item no-select">
                    <TemplateButton
                        icon={<LogoutIcon style={{width: 25, height: 25}}/>}
                        text='Log Out'
                        color='serious'
                        onClickFunction={logOutUser}
                        type='navbar-button'
                    />
                </div>
            </div>
            
        </div>
    )
}