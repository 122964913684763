// Import the functions you need from the SDKs you need
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCANUQT6tHcOiJk5abEQzV-nMuhI_YeDjU",
  authDomain: "proj-quwoperate.firebaseapp.com",
  databaseURL: "https://proj-quwoperate-default-rtdb.firebaseio.com",
  projectId: "proj-quwoperate",
  storageBucket: "proj-quwoperate.appspot.com",
  messagingSenderId: "808549840978",
  appId: "1:808549840978:web:f2506d878b80f1c766878b",
  measurementId: "G-HTJ93F7WQY"
};

// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebase.database();
const auth = firebase.auth();
// const analytics = getAnalytics(app);

export {firebaseApp, db, auth}
