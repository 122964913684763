import * as React from 'react';
import { auth } from '../../firebase';

import Navbar from "../../reusableComponents/navbar";

export default function Budget() {

    auth.onAuthStateChanged((user) => {
        if (user) {
        } else {
            window.location.pathname = '/';
        }
    });
    
    return (
        
        <div> 
            <Navbar active='budget' />
            BUDGET???????????? OMG!!!!!!!!! ily Naadu ❤️ 
        </div>
    )
}