import { db } from '../firebase';

import { setAllGroups, setActiveGroup } from '../app/groupsSlice';

export function getAndSetGroups(dispatch, user) {
        // Get last active group
        getLastActiveGroup(dispatch, user.uid)

        // Get all of user's groups
        getAllOfThisUsersGroups(dispatch, user.uid)
}

export function getLastActiveGroup(dispatch, userId) {
    db.ref('users/' + userId + '/lastActive/').on('value', (snapshot) => {
        let last_active_group = snapshot.val();
        if (last_active_group === null) {
            console.log("No active group found on groups page load.")
            return
        }
        dispatch(setActiveGroup({id: last_active_group.groupId, name: last_active_group.groupName}))
    })
}

export function getAllOfThisUsersGroups(dispatch, userId) {
    db.ref('users/' + userId + '/memberships/').on('value', (allMembershipsSnapshot) => {
        let allGroupsArr = []
        if (allMembershipsSnapshot.val() === null) {
            console.log("Not a single group found on groups page load.")
            return
        }
        Object.keys(allMembershipsSnapshot.val()).forEach(function(key) {
            allGroupsArr.push({
                id: key,
                name: allMembershipsSnapshot.val()[key]
            })
        });
        dispatch(setAllGroups(allGroupsArr))
    })
}

export function setActiveGroupInDB(dispatch, uid, groupId, groupName) {
    db.ref().child('users/'+uid).child('/lastActive/').set({
        groupId: groupId,
        groupName: groupName
    })
}