
import './index.css'

export default function Event({
    author,
    name,
    color
}) {

    return(
        <div className="event-root no-select" style={{backgroundColor: color}}>
            {name} - <i>{author}</i>
        </div>
    )
}