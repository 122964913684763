import './../index.css';

export default function TemplateButton({color, text, icon, onClickFunction, size, type}) {

    return(
        <span>
            <div 
                className={"bigbutton-outer bigbutton-outer-"+ color + " bigbutton-template no-select bigbutton-template-" + size + " bigbutton-template-" + type}
                onClick={onClickFunction}
            >
                {icon}
                {text}
            </div>
        </span>
    )
}